import ClampLines from 'react-clamp-lines';
import fallbackImg from 'assets/imgs/fallback.png';
import './index.scss';
import { getPartnersContent, PartnersContent } from 'lib/apis';
import { useEffect, useState } from 'react';

const PartnersPage = () => {
  const [content, setContent] = useState<PartnersContent | undefined>(
    undefined
  );

  useEffect(() => {
    getPartnersContent().then(response => setContent(response));
  }, []);

  if (content === undefined) {
    return (
      <div className="partners-page">
        <div className="title">Partners</div>
      </div>
    );
  }

  return (
    <div className="partners-page">
      <div className="title">Partners</div>
      <p className="description">{content.info.description}</p>
      <div className={'partners-list-container'}>
        {content.partners.map((partnerItem, index) => (
          <div className="partner-card" key={index}>
            <div className="img-block">
              <img
                src={partnerItem?.image?.url || fallbackImg}
                alt={partnerItem.name}
              />
            </div>
            <div className="text-block">
              <div className="name">{partnerItem.name}</div>
              <div className="type">{partnerItem.specification}</div>
            </div>
            <div className="overflowed-block">
              <div className="name">{partnerItem.name}</div>
              <div className="type">{partnerItem.specification}</div>
              <ClampLines
                text={partnerItem.info || ''}
                id={`card-text ${index}`}
                lines={5}
                ellipsis="..."
                className="text"
                innerElement="div"
                buttons={false}
              />
              <a target="_blank" href={partnerItem.url} rel="noreferrer">
                <button type="button">More info</button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersPage;
