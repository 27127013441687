import { FC } from 'react';
import { Link } from 'react-router-dom';
import { home } from 'lib/constants/routes';
import { LandQueryResult } from 'lib/apis';

import DiyListItem from './DiyListItem';
import ListItem from './ListItem';
import ListItemSkeleton from './ListItemSkeleton';
import './opportunities.scss';
import EmptyOpportunity from './EmptyOpportunity';
import NotHappyOptions from './NotHappyOptions';
import ApplyToAll from './ApplyToAll';

export type Metrics = {
  Timescale: number;
  Difficulty: number;
  Value: number;
  'Eco Planning': number;
  'Land Retention': number;
};

interface Props {
  data?: LandQueryResult;
}

const Opportunities: FC<Props> = ({ data }) => {
  if (!data) {
    return <ListItemSkeleton />;
  }

  const allOpportunities = data.available_opportunities.opportunities;
  const diyOpportunities = allOpportunities.filter(opp => opp.diy);
  const commercialOpportunities = allOpportunities.filter(opp => !opp.diy);

  const renderCommercialOpps = () => {
    if (!allOpportunities) {
      return <ListItemSkeleton />;
    }

    return (
      <>
        <div className="opportunties-heading-bar">
          <h1>
            {commercialOpportunities?.length}{' '}
            {commercialOpportunities && commercialOpportunities.length != 1
              ? 'Opportunities'
              : 'Opportunity'}
          </h1>
          <div className="apply-to-all-container">
            {commercialOpportunities?.length &&
              commercialOpportunities.length > 1 && (
                <ApplyToAll
                  postcode={commercialOpportunities[0].postcode}
                  title={commercialOpportunities
                    .map(itm => itm.opportunity_name)
                    .join(', ')}
                />
              )}
          </div>
        </div>
        {commercialOpportunities?.length === 0 && <EmptyOpportunity />}
        {commercialOpportunities?.map(item => (
          <ListItem key={item.opportunity_name} opportunity={item} />
        ))}
      </>
    );
  };

  const renderAlternativeOpps = () => {
    if (diyOpportunities?.length == 0) {
      return <></>;
    }

    return (
      <>
        <h1>Inspiration</h1>
        {diyOpportunities?.map(item => (
          <DiyListItem key={item.opportunity_name} opportunity={item} />
        ))}
      </>
    );
  };

  return (
    <div className="opportunities-outer-container">
      <div className="header"></div>
      <main className="main-content">
        <div className="opportunities-top-bar">
          <div className="back-link-container">
            <Link className="back-link" to={home}>
              &lt; Back to Search
            </Link>
          </div>
          <div className="plot-pip-container">
            <div className="plot-pip">
              <div>Your Plot</div>
              <div>{localStorage.getItem('acres')} Acres</div>
            </div>
          </div>
        </div>
        {renderCommercialOpps()}
        {diyOpportunities && renderAlternativeOpps()}
      </main>
      <NotHappyOptions />
    </div>
  );
};

export default Opportunities;
