import React, { useEffect, useRef } from 'react';
import mapboxgl, { MapLayerMouseEvent } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { MAP_BOX_API_TOKEN } from 'lib/constants/envConstants';
import {
  getLandQuery,
  LandQueryResult,
  PolygonCoordinate,
  processLandQuery
} from 'lib/apis';

// @ts-ignore
mapboxgl.accessToken = MAP_BOX_API_TOKEN;

type ApplicationMapParams = {
  acreage: number;
  landPolygon: PolygonCoordinate[];
};

const getCentreOf = (polygon: PolygonCoordinate[]): [number, number] => {
  let sumX = 0;
  let sumY = 0;

  for (const point of polygon) {
    sumX += point.x;
    sumY += point.y;
  }

  const centerX = sumX / polygon.length;
  const centerY = sumY / polygon.length;

  return [centerX, centerY];
};

const ApplicationMap: React.FC<ApplicationMapParams> = (
  params: ApplicationMapParams
) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const setMap = (landResult: LandQueryResult) => {
    if (!map.current) {
      return; // Initialize map only once
    }

    const layerIds = ['polygon-layer'];
    const featuresWithPolygons = landResult.land_features.filter(
      lf => lf.polygon
    );

    featuresWithPolygons.forEach((landFeature, index) => {
      const featureId = `land-feature-${index}`;
      layerIds.push(`${featureId}-layer`);

      const polyg = landFeature.polygon ?? {
        type: 'MultiPolygon',
        coordinates: []
      };

      map.current?.addSource(featureId, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: polyg.type,
            coordinates: polyg.coordinates
          },
          properties: {
            title: landFeature.name,
            description: ''
          }
        }
      });

      // Add the polygon layer to display it
      map.current?.addLayer({
        id: `${featureId}-layer`,
        type: 'fill',
        source: featureId,
        layout: {},
        paint: {
          'fill-color': '#4974a5',
          'fill-opacity': 0.5
        }
      });

      // Add a border to the polygon
      map.current?.addLayer({
        id: `${featureId}-outline`,
        type: 'line',
        source: 'polygon',
        layout: {},
        paint: {
          'line-color': '#4974a5',
          'line-width': 2
        }
      });
    });

    map.current?.addSource('polygon', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [params.landPolygon.map(coord => [coord.x, coord.y])]
        },
        properties: {
          title: 'Land Area',
          description: 'This is the area of land drawn by the applicant.'
        }
      }
    });

    // Add the polygon layer to display it
    map.current?.addLayer({
      id: 'polygon-layer',
      type: 'fill',
      source: 'polygon',
      layout: {},
      paint: {
        'fill-color': '#fc8124',
        'fill-opacity': 0.5
      }
    });

    // Add a border to the polygon
    map.current?.addLayer({
      id: 'polygon-outline',
      type: 'line',
      source: 'polygon',
      layout: {},
      paint: {
        'line-color': '#fc8124',
        'line-width': 2
      }
    });

    map.current?.on('click', layerIds, (e: MapLayerMouseEvent) => {
      const feature = e.features?.at(0) || {
        properties: { title: '', description: '' }
      };

      new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(
          `<b>${feature.properties?.title}</b><br />
          ${feature.properties?.description}`
        )
        // eslint-disable-next-line
        .addTo(map.current!);
    });

    map.current?.on('mouseenter', layerIds, () => {
      const canvas = map.current?.getCanvas();

      if (canvas) {
        canvas.style.cursor = 'pointer';
      }
    });

    map.current?.on('mouseleave', layerIds, () => {
      const canvas = map.current?.getCanvas();

      if (canvas) {
        canvas.style.cursor = '';
      }
    });
  };

  const pollUrl = async (queryId: number) => {
    const response = await getLandQuery(queryId);

    if (response.status === 200) {
      setMap(response.data);
    } else {
      setTimeout(() => pollUrl(queryId), 2000);
    }
  };

  useEffect(() => {
    if (map.current) {
      return; // Initialize map only once
    }

    // Create map instance
    map.current = new mapboxgl.Map({
      container: mapContainer.current || '',
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: getCentreOf(params.landPolygon),
      zoom: 14,
      preserveDrawingBuffer: true
    });

    // Add navigation control to the map
    map.current.addControl(new mapboxgl.NavigationControl());

    processLandQuery(params.landPolygon, params.acreage).then(x => {
      pollUrl(x.id);
    });

    // Cleanup on unmount
    return () => {
      map.current?.remove();
    };
  }, []);

  return <div ref={mapContainer} style={{ width: '100%', height: '500px' }} />;
};

export default ApplicationMap;
