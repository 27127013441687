import { FC } from 'react';
import { Container } from '@mui/material';
import './StatsBanner.scss';
import { HomepageJourney, HomepageSat } from 'lib/apis';

import arrow from './imgs/chevron-right.svg';

interface Props {
  stats?: HomepageSat[] | undefined;
  journeyItems?: HomepageJourney[] | undefined;
}

const StatsBannner: FC<Props> = ({ stats, journeyItems }) => {
  if (journeyItems === null || journeyItems === undefined) {
    return null;
  }

  const journeyLastIndex = journeyItems.length - 1;

  return (
    <div className="stats-banner-section">
      <div className="background">
        <Container
          className="background-container"
          maxWidth="xl"
          style={{ position: 'relative' }}
        >
          <div className="shifted-container">
            <div className="arrow-box"></div>
          </div>
          <div className="top-layer">
            {journeyItems.map(
              ({ icon: { url }, title: header, text }, index) => {
                return (
                  <>
                    <div className="stat-item" key={`ji_${index}`}>
                      <img src={url} />
                      <h2>{header}</h2>
                      <p>{text}</p>
                    </div>

                    {journeyLastIndex != index ? (
                      <div className="arrow-icon">
                        <img src={arrow} />
                      </div>
                    ) : null}
                  </>
                );
              }
            )}
          </div>
        </Container>
      </div>
      {stats === null || stats === undefined ? null : (
        <div className="cards-box">
          <Container
            maxWidth="xl"
            className="background-container"
            style={{ position: 'relative' }}
          >
            <div className="cards-flexbox">
              {stats.map(({ large_text: header, small_text: text }, index) => (
                <div key={`stat_${index}`}>
                  <h2>{header}</h2>
                  <p>{text}</p>
                </div>
              ))}
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default StatsBannner;
