import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import {
  mapRoute,
  aboutUsRoute,
  partnersRoute,
  opportunities,
  application,
  news,
  newsRoute,
  contactUsRoute,
  loginRoute,
  adminApplicationsRoute
} from 'lib/constants/routes';
import useScrollToTop from 'lib/helpers/useScrollTop';
import ReactGA from 'react-ga';
import ProtectedRoutes from 'lib/helpers/ProtectedRoutes';

import AboutUsPage from 'components/pages/AboutUs';
import ApplicationPage from 'components/pages/Application';
import HomePageContainer from 'components/pages/Home/HomePageContainer';
import MapDrawPageContainer from 'components/pages/MapDraw/MapDrawPageContainer';
import NewsPageContainer from 'components/pages/News/NewsPageContainer';
import OpportunitiesPage from 'components/pages/Opportunities';
import PartnersPage from 'components/pages/Partners';
import ArticlePageContainer from './components/pages/Article/ArticlePageContainer';
import ContactUsPage from 'components/pages/ContactUs';
import { LoadingProps } from 'components/shared/LoadingModal/LoadingModal';
import LoginPage from 'components/pages/Admin/login';
import AdminApplicationsPage from 'components/pages/Admin/applications';
import AdminApplicationPage from 'components/pages/Admin/application';

export type CommonProps = {
  setLoadingCallback: React.Dispatch<React.SetStateAction<LoadingProps>>;
};

function Router({ setLoadingCallback }: CommonProps) {
  useScrollToTop();
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePageContainer />} />
      <Route path={mapRoute} element={<MapDrawPageContainer />} />
      <Route path={aboutUsRoute} element={<AboutUsPage />} />
      <Route path={contactUsRoute} element={<ContactUsPage />} />
      <Route path={partnersRoute} element={<PartnersPage />} />
      <Route path={loginRoute} element={<LoginPage />} />
      <Route element={<ProtectedRoutes />}>
        <Route
          path={adminApplicationsRoute}
          element={<AdminApplicationsPage />}
        />
        <Route
          path={`${adminApplicationsRoute}/:applicationId`}
          element={<AdminApplicationPage />}
        />
      </Route>
      <Route
        path={opportunities}
        element={<OpportunitiesPage setLoadingCallback={setLoadingCallback} />}
      />
      <Route
        path={application}
        element={<ApplicationPage setLoadingCallback={setLoadingCallback} />}
      />
      <Route path={news} element={<NewsPageContainer />} />
      <Route path={newsRoute} element={<ArticlePageContainer />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default Router;
