import { useEffect, useState } from 'react';
import { getNewsContent, NewsContent } from 'lib/apis';

import NewsPage from './NewsPage';

const NewsPageContainer = () => {
  const [content, setContent] = useState<NewsContent | undefined>(undefined);

  useEffect(() => {
    getNewsContent().then(response => setContent(response));
  }, []);

  return content ? <NewsPage articles={content.news_items} /> : null;
};

export default NewsPageContainer;
