import { API_URL } from 'lib/constants/envConstants';
import axios, { AxiosResponse } from 'axios';

type ContactUsDetails = {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  message: string;
};

export type NoOpApplicationInfo = {
  acres: Number;
  email: string;
  polygon: string;
  screenshot: string;
};

export type AuthenticationRequest = {
  email: string;
  password: string;
};

export type PolygonCoordinate = {
  x: number;
  y: number;
};

export type Application = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  postcode: string;
  screenshot: string;
  opportunity: string;
  polygon: PolygonCoordinate[];
  priceEstimate: number;
  acres: number;
};

export type ApplicationSummary = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  postcode: string;
  opportunity: string;
};

interface LandFeature {
  name: string;
  type: string;
  polygon?: MultiPolygon;
}

type Position = [number, number];

type LinearRing = Position[];

type Polygon = LinearRing[];

type MultiPolygon = {
  type: 'MultiPolygon';
  coordinates: Polygon[];
};

type Score = {
  name: string;
  description: string;
  display: boolean;
  value: number;
};

export type Scores = {
  difficulty: Score;
  eco_friendly: Score;
  land_retention: Score;
  planning_constraints: Score;
  timescale: Score;
  value: Score;
};

type Step = {
  id: number;
  step: string;
  instructions: string;
};

export type AvailableOpportunity = {
  opportunity_name: string;
  opportunity_description: string;
  opportunity_image: string;
  price: number;
  postcode: string;
  diy: boolean;
  diy_provider_url: string;
  diy_provider_image: string;
  payment_type: string;
  payment_type_info: string;
  scores: Scores;
  steps: Step[];
};

export type AvailableOpportunities = {
  acreage: number;
  agr_grade: number;
  opportunities: AvailableOpportunity[];
};

export interface LandQueryResult {
  land_features: LandFeature[];
  available_opportunities: AvailableOpportunities;
}

export interface TeamMember {
  id: number;
  about: string;
  name: string;
  position: string;
  image: {
    url: string;
  };
}

export interface UpAcreTimeline {
  id: number;
  date: string;
  title: string;
  description: string;
  image: {
    url: string;
  };
}

export interface AboutUsContent {
  info_a: string;
  info_b: string;
  team_members: TeamMember[];
  up_acre_timeline: UpAcreTimeline[];
}

export interface NewsItem {
  id: number;
  title: string;
  short_description: string;
  content: string;
  image: {
    url: string;
  };
}

export interface NewsContent {
  news_items: NewsItem[];
}

export type PartnersContent = {
  info: {
    description: string;
  };
  partners: Partner[];
};

export type Partner = {
  id: number;
  name: string;
  image: {
    url: string;
  };
  specification: string;
  info: string;
  url: string;
};

export type HomepageJourney = {
  id: number;
  title: string;
  text: string;
  icon: {
    url: string;
  };
};

export type HomepageSat = {
  id: number;
  small_text: string;
  large_text: string;
};

export type HomepageSection = {
  id: number;
  title: string;
  description: string;
  button_text: string;
  button_link_type: string;
  button_link: string;
  background_image: string;
  image: {
    url: string;
  };
};

export type HomepageContent = {
  journeys: HomepageJourney[];
  stats: HomepageSat[];
  sections: HomepageSection[];
};

export type PaymntTypeContent = {
  total: string;
  deposit: string;
  per_month: string;
  per_annum: string;
};

export type MapPage = {
  help_text: string;
  video_url: string;
};

export type ForwardGeocodeResult = {
  id: string;
  center: [number, number];
  placeName: string;
  placeText: string;
};

export type ForwardGeocodeResults = {
  results: ForwardGeocodeResult[];
};

export type ValidAreaResult = {
  isValid: boolean;
  isLondon: boolean;
};

function authHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.accessToken}`
  };
}

export async function contactUs(params: ContactUsDetails) {
  return await axios.post(
    API_URL + '/contact-us',
    {
      firstName: params.firstName,
      lastName: params.lastName,
      email: params.email,
      telephone: params.telephone,
      message: params.message
    },
    { headers: authHeaders() }
  );
}

export async function noOpportunity(params: NoOpApplicationInfo) {
  return await axios.post(
    API_URL + '/no-opportunity',
    {
      acres: params.acres,
      polygon: params.polygon,
      email: params.email,
      screenshot: params.screenshot
    },
    { headers: authHeaders() }
  );
}

export async function authenticate(params: AuthenticationRequest) {
  return await axios.post(
    API_URL + '/authenticate',
    {
      email: params.email,
      password: params.password
    },
    { headers: authHeaders() }
  );
}

export async function getApplications() {
  return await axios.get(API_URL + '/applications', { headers: authHeaders() });
}

export async function getApplication(applicationId: string) {
  return await axios.get(API_URL + `/applications/${applicationId}`, {
    headers: authHeaders()
  });
}

export async function getLandQuery(
  id: number
): Promise<AxiosResponse<LandQueryResult>> {
  return await axios.get<LandQueryResult>(API_URL + `/land-query/${id}`);
}

export async function processLandQuery(
  polygon: PolygonCoordinate[],
  acreage: number
): Promise<{ id: number }> {
  return (
    await axios.post<{ id: number }>(API_URL + `/land-query`, {
      polygon,
      acreage
    })
  ).data;
}

export async function getAboutUsContent(): Promise<AboutUsContent> {
  return (await axios.get(API_URL + '/content/about-us')).data;
}

export async function getNewsContent(): Promise<NewsContent> {
  return (await axios.get(API_URL + '/content/news-items')).data;
}

export async function getPartnersContent(): Promise<PartnersContent> {
  return (await axios.get(API_URL + '/content/partners')).data;
}

export async function getHomepageContent(): Promise<HomepageContent> {
  return (await axios.get(API_URL + '/content/homepage')).data;
}

export async function getPaymentTypeContent(): Promise<PaymntTypeContent> {
  return (await axios.get(API_URL + '/content/payment-types')).data;
}

export async function getMapPageContent(): Promise<MapPage> {
  return (await axios.get(API_URL + '/content/map-page')).data;
}

export async function getForwardGeocode(
  search: string
): Promise<ForwardGeocodeResults> {
  return (await axios.post(API_URL + '/forward-geocode', { search })).data;
}

export async function getValidArea(
  polygon: PolygonCoordinate[]
): Promise<ValidAreaResult> {
  return (
    await axios.post<ValidAreaResult>(API_URL + `/valid-area`, { polygon })
  ).data;
}
