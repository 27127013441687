import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { application } from 'lib/constants/routes';
import useMediaQuery from 'lib/helpers/useMediaQuery';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Gauge } from 'react-circular-gauge';
import ProgressBar from 'lib/helpers/ProgressBar';
import { AvailableOpportunity } from 'lib/apis';

import MetricModal from './MetricModal';
import { ReactComponent as ArrowIcon } from './imgs/thin-arrow.svg';
import './ListItem.scss';
import { Metrics } from './Opportunities';

function detectColor(value: number | boolean): string {
  if (typeof value === 'boolean') {
    return value ? '#5EBF2E' : '#DE2525';
  }

  switch (true) {
    case value >= 0 && value <= 20:
      return '#DE2525';
    case value > 20 && value <= 50:
      return '#BF6A2E';
    case value > 50 && value <= 75:
      return '#BFA72E';
    case value > 75 && value <= 100:
      return '#9BBF2E';
    default:
      return '#5EBF2E';
  }
}

const sortValues: Record<keyof Metrics, string> = {
  Timescale: 'Time',
  Difficulty: 'Difficulty',
  Value: 'Planning Constraints',
  'Eco Planning': 'Biodiversity Net Gain',
  'Land Retention': 'Land Retention'
};

interface Props {
  opportunity: AvailableOpportunity;
}

const newScoreNames: { [key: string]: string } = {
  Timescale: 'Time',
  Difficulty: 'Difficulty',
  Value: 'Planning Constraints',
  'Eco Planning': 'Biodiversity Net Gain',
  'Land Retention': 'Land Retention'
};

const ListItem: FC<Props> = ({ opportunity }) => {
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  const handleShowDetails = () => {
    setShowDetails(prev => !prev);
  };

  const isTabletOrMobile = useMediaQuery('(max-width: 1280px)');
  const isMobile = useMediaQuery('(max-width: 900px)');

  const iconStyle = (value: number) => ({
    ...(!isMobile && {
      backgroundColor: '#F5F5F5',
      borderRadius: '100px',
      padding: '15%'
    }),
    color: detectColor(value),
    width: `${isMobile ? '1em' : '100%'}`,
    height: `${isMobile ? '1em' : '100%'}`
  });

  return (
    <>
      <MetricModal
        open={!!selected}
        selected={selected}
        handleClose={() => {
          setSelected(null);
        }}
        scores={opportunity.scores}
      />
      <div
        className={`list-item-with-details ${
          showDetails ? 'details-opened' : ''
        }`}
      >
        <div className={'list-item-container'}>
          <div className="image-container">
            <div
              className="img"
              style={{
                backgroundImage: `url(${opportunity.opportunity_image})`
              }}
            >
              {isTabletOrMobile && 0.5 > 0 ? null : (
                <button type="button" onClick={() => handleShowDetails()}>
                  <ArrowIcon className={showDetails ? 'up' : ''} />
                  &nbsp; More Info
                </button>
              )}
            </div>
          </div>
          <div className="main-content">
            <h3>{opportunity.opportunity_name}</h3>
            <div className="tags">
              {Object.entries(opportunity.scores)
                .filter(([_key, score]) => score.display)
                .map(([key, score]) => {
                  return (
                    <div
                      className="tag"
                      key={key}
                      onClick={() => setSelected(newScoreNames[score.name])}
                    >
                      <div className="tag-gauge">
                        {
                          //@ts-ignore
                          sortValues[score.name] == 'Land Retention' ? (
                            score.value ? (
                              <CheckCircleIcon style={iconStyle(score.value)} />
                            ) : (
                              <CancelIcon style={iconStyle(score.value)} />
                            )
                          ) : isMobile ? (
                            <ProgressBar
                              customColor={detectColor(score.value)}
                              value={score.value}
                              variant="determinate"
                            />
                          ) : (
                            <Gauge
                              value={score.value}
                              minValue={0}
                              maxValue={100}
                              startAngle={180}
                              endAngle={180}
                              arcColor={detectColor(score.value)}
                              arcWidth={0.2}
                              arcCornerRadius={0}
                              trackColor={detectColor(score.value) + '44'}
                              trackWidth={0.2}
                              direction="ccw"
                              valueStyle={{
                                color: detectColor(score.value),
                                fontWeight: 'bold'
                              }}
                            />
                          )
                        }
                      </div>
                      <div className="tag-label">
                        {
                          //@ts-ignore
                          sortValues[key]
                        }
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="price-container">
            <div className={'price-info'}>
              {typeof opportunity.price === 'number' ? (
                <span>
                  <b>
                    {new Intl.NumberFormat('us-gb', {
                      style: 'currency',
                      currency: 'GBP'
                    }).format(opportunity.price)}{' '}
                  </b>
                  {opportunity.payment_type}*
                </span>
              ) : (
                <span className="not-available">{opportunity.price}</span>
              )}
              <span>
                *Valuations are strictly estimates only, and you should use them
                on that basis
                <span>&nbsp;&mdash;&nbsp;{opportunity.payment_type_info}</span>
              </span>
            </div>
            <div className="apply-button-container">
              <button
                type="button"
                className="btn-orange"
                onClick={() => {
                  localStorage.setItem(
                    'opportunityDetails',
                    JSON.stringify({
                      variant: opportunity.opportunity_name,
                      postcode: opportunity.postcode,
                      price: opportunity.price
                    })
                  );
                  localStorage.setItem('stage', '1');
                  navigate(application);
                }}
              >
                Apply
                <ArrowIcon />
              </button>
            </div>
          </div>
          {isTabletOrMobile ? (
            <div className="more-info-btn-container">
              <button
                type="button"
                className="btn-orange"
                onClick={() => handleShowDetails()}
              >
                More Info
                <ArrowIcon className={showDetails ? 'up' : ''} />
              </button>
            </div>
          ) : null}
        </div>
        <div className={`details ${showDetails ? 'show' : ''}`}>
          <h5>More Information</h5>
          <p>{opportunity.opportunity_description}</p>
        </div>
      </div>
    </>
  );
};

export default ListItem;
