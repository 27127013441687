import './index.scss';
import { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { mapRoute } from 'lib/constants/routes';
import {
  NotificationManager
  // @ts-ignore
} from 'react-notifications';
import {
  ForwardGeocodeResult,
  getForwardGeocode,
  getValidArea
} from 'lib/apis';

import searchIcon from './imgs/search.svg';

type SearchBannerCTAProps = {
  title: string;
};

const SearchBannerCTA = (props: SearchBannerCTAProps) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [autocompleteItems, setAutocompleteItems] = useState<
    ForwardGeocodeResult[]
  >([]);

  const [autocompleteItemsLoading, setAutocompleteItemsLoading] =
    useState<boolean>(false);

  const [selectedCoordinates, setSelectedCoordinates] = useState<
    number[] | null
  >(null);

  const handleSelectItem = (item: ForwardGeocodeResult) => {
    setSearchTerm(item.placeName);
    setSelectedCoordinates(item.center.map(Number));
    setAutocompleteItems([]);
  };

  const handleSearchInputChange = (term: string) => {
    setSearchTerm(term);
    fetchAutocompleteItems(term);
  };

  const fetchItems = async (searchString: string) => {
    const data = await getForwardGeocode(searchString);

    setSelectedCoordinates(data.results[0].center.map(Number));

    return data.results;
  };

  const fetchAutocompleteItems = useCallback(
    debounce(async (searchString: string) => {
      if (!searchString) {
        setAutocompleteItems([]);

        return;
      }

      try {
        setAutocompleteItemsLoading(true);
        const result = await fetchItems(searchString);
        setAutocompleteItems(result);
      } catch (error) {
        console.error(error);
      } finally {
        setAutocompleteItemsLoading(false);
      }
    }, 250),
    []
  );

  const autocompleteList = () => {
    const items = [];

    if (autocompleteItemsLoading) {
      items.push(
        <div key="loading" className="item loading">
          Loading ...
        </div>
      );
    } else {
      autocompleteItems.forEach(item => {
        items.push(
          <div
            onClick={() => handleSelectItem(item)}
            key={item?.id}
            className="item"
          >
            {item.placeName}
          </div>
        );
      });
    }

    return (
      <div className="search-autocomplete-list">{items.map(item => item)}</div>
    );
  };

  const handleSubmit = async () => {
    const resultForMap = (await fetchItems(searchTerm))[0];

    const res = await getValidArea(
      Array(4).fill({
        x: selectedCoordinates?.[0],
        y: selectedCoordinates?.[1]
      })
    );

    const { isLondon, isValid } = res;

    if (!isValid && !isLondon) {
      NotificationManager.warning(
        'Your land is not within our current catchment. However, it will be available next year'
      );

      return;
    }

    if (!isValid && isLondon) {
      NotificationManager.warning(
        'Thank you for your submission. Unfortunately, we are currently not operating in London and surrounding boroughs due to various planning and geographical constraints. We thank you once again for your submission and wish you all the best in your future land endeavours․',
        '',
        20000
      );

      return;
    }

    if (!resultForMap) {
      navigate('/');

      return;
    }

    if (resultForMap) {
      const { placeName, center } = resultForMap;

      navigate({
        pathname: mapRoute,
        search: `?${createSearchParams({
          place: placeName,
          center: center.join(',')
        })}`
      });
    } else {
      navigate(mapRoute);
    }
  };

  return (
    <div className="search-banner-cta">
      <div className="content-container">
        <h3>{props.title}</h3>
        <div className="search-input">
          <div className="input-inner">
            <input
              placeholder="Enter your postcode"
              defaultValue={searchTerm}
              onChange={e => handleSearchInputChange(e.target.value)}
            />
            <img src={searchIcon} alt="" />
          </div>
          {autocompleteList()}
        </div>
        <button
          type="button"
          className={`btn-orange${searchTerm === '' ? ' disabled' : ''}`}
          disabled={searchTerm === ''}
          onClick={handleSubmit}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchBannerCTA;
