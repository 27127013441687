import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './login.scss';
import { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { authenticate } from 'lib/apis';
import { adminApplicationsRoute } from 'lib/constants/routes';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const passwordRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.accessToken !== undefined) {
      navigate(adminApplicationsRoute);
    }
  });

  const login = async (): Promise<void> => {
    setProcessing(true);

    try {
      const response = await authenticate({
        email: email,
        password: password
      });

      localStorage.setItem('accessToken', response.data.token);

      navigate(adminApplicationsRoute);
    } catch {
      await NotificationManager.error('Invalid username or password!');

      if (passwordRef.current) {
        passwordRef.current.value = '';
        setTimeout(() => passwordRef.current?.focus(), 250);
      }
    }

    setProcessing(false);
  };

  return (
    <div className="login-page">
      <NotificationContainer />
      <div className="content-container">
        <h1 className="title">Login</h1>
      </div>
      <div className="login-container">
        <form
          className="form"
          onSubmit={e => {
            e.preventDefault();
            login();
          }}
        >
          <div className="input-field-row">
            <label htmlFor="username">Email</label>
            <input
              type="text"
              id="username"
              name="username"
              onChange={e => setEmail(e.target.value)}
              disabled={processing}
              autoFocus
            />
          </div>
          <div className="input-field-row">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={e => setPassword(e.target.value)}
              ref={passwordRef}
              disabled={processing}
            />
          </div>
          <div className="input-field-row">
            <button type="submit" className="btn-orange" disabled={processing}>
              {processing && <ClipLoader color="#fc8124" />}
              {!processing && 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
