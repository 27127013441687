import { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { mapRoute } from 'lib/constants/routes';
import {
  NotificationManager
  // @ts-ignore
} from 'react-notifications';
import {
  ForwardGeocodeResult,
  getForwardGeocode,
  getValidArea
} from 'lib/apis';

import HomePage from './HomePage';

const HomePageContainer = () => {
  const [autocompleteItems, setAutocompleteItems] = useState<
    ForwardGeocodeResult[]
  >([]);

  const [autocompleteItemsLoading, setAutocompleteItemsLoading] =
    useState<boolean>(false);

  const [disabled, setDisabled] = useState(false);

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [selectedCoordinates, setSelectedCoordinates] = useState<
    number[] | null
  >(null);

  const navigate = useNavigate();

  const handleInputChange = (value: string) => {
    setSearchInputValue(value);
    fetchAutocompleteItems(value);
  };

  const handleSelectItem = (item: ForwardGeocodeResult) => {
    setSearchInputValue(item.placeName);
    setSelectedCoordinates(item.center.map(Number));
    setAutocompleteItems([]);
  };

  const fetchItems = async (searchString: string) => {
    const data = await getForwardGeocode(searchString);

    setSelectedCoordinates(data.results[0].center.map(Number));

    return data.results;
  };

  const fetchAutocompleteItems = useCallback(
    debounce(async (searchString: string) => {
      if (!searchString) {
        setAutocompleteItems([]);

        return;
      }

      try {
        setAutocompleteItemsLoading(true);
        const result = await fetchItems(searchString);
        setAutocompleteItems(result);
      } catch (error) {
        console.error(error);
      } finally {
        setAutocompleteItemsLoading(false);
      }
    }, 250),
    []
  );

  const onSearchSubmit = async () => {
    setDisabled(true);
    const resultForMap = (await fetchItems(searchInputValue))[0];

    const res = await getValidArea(
      Array(4).fill({
        x: selectedCoordinates?.[0],
        y: selectedCoordinates?.[1]
      })
    );

    const { isLondon, isValid } = res;

    setDisabled(false);

    if (!isValid && !isLondon) {
      NotificationManager.warning(
        'Your land is not within our current catchment. However, it will be available next year'
      );

      return;
    }

    if (!isValid && isLondon) {
      NotificationManager.warning(
        'Thank you for your submission. Unfortunately, we are currently not operating in London and surrounding boroughs due to various planning and geographical constraints. We thank you once again for your submission and wish you all the best in your future land endeavours․',
        '',
        20000
      );

      return;
    }

    if (!resultForMap) {
      navigate('/');

      return;
    }

    if (resultForMap) {
      const { placeName, center } = resultForMap;

      navigate({
        pathname: mapRoute,
        search: `?${createSearchParams({
          place: placeName,
          center: center.join(',')
        })}`
      });
    } else {
      navigate(mapRoute);
    }
  };

  useEffect(() => {
    const termly = localStorage.getItem('TERMLY_API_CACHE');
    localStorage.clear();

    if (termly) {
      localStorage.setItem('TERMLY_API_CACHE', termly);
    }
  }, []);

  return (
    <HomePage
      {...{
        autocompleteItems,
        autocompleteItemsLoading,
        searchInputValue,
        handleInputChange,
        onSearchSubmit,
        handleSelectItem,
        disabled
      }}
    />
  );
};

export default HomePageContainer;
