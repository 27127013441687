import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';

export interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol style={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
        {items.map((item, index) => (
          <li key={index} style={{ marginRight: '8px' }}>
            {index < items.length - 1 ? (
              <>
                <Link to={item.path}>{item.label}</Link>
                <span style={{ margin: '0 8px' }}>/</span>
              </>
            ) : (
              <span>{item.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
