const instagram = 'https://www.instagram.com/up.acre/';
const facebook = 'https://www.facebook.com/Up-Acre-100113736156701';
const twitter = 'https://twitter.com/up_acre';
const linkedin = 'https://www.linkedin.com/company/up-acre-ltd';
const privacyPolicyRoute =
  'https://app.termly.io/document/privacy-policy/77aa2877-6843-48f5-aa06-9d45c4c982ab';

const termsAndConditionsRoute =
  'https://app.termly.io/document/terms-of-use-for-website/abd5f908-a850-4faa-af7b-6a4d55295880';

const disclaimerRoute =
  'https://app.termly.io/document/disclaimer/a9170091-a8bc-4cfb-a2b5-e68292adb54c';

const cookiesPolicy =
  'https://app.termly.io/document/cookie-policy/5af7909a-0713-46c1-975f-b5b2ee239dd9';

const manageConsentRoute = '/manage-consent';
const aboutUsRoute = '/about';
const contactUsRoute = '/contact';
const partnersRoute = '/partners';
const loginRoute = '/login';
const adminApplicationsRoute = '/admin/applications';
const mapRoute = '/map-draw';
const newsRoute = '/blog/:id';
const signInRoute = '/sign-in';
const signUpRoute = '/sign-up';
const forgotPassword = '/forgot-password';
const resetPassword = '/reset-password/:token';
const opportunities = '/opportunities';
const home = '/home';
const application = '/application';
const dashboard = '/dashboard';
const news = '/blog';
const accounts = '/accounts';
const projects = '/projects';
const project = '/projects/project/:projectId';

export {
  manageConsentRoute,
  aboutUsRoute,
  partnersRoute,
  mapRoute,
  newsRoute,
  signInRoute,
  signUpRoute,
  opportunities,
  home,
  application,
  dashboard,
  news,
  accounts,
  projects,
  project,
  forgotPassword,
  resetPassword,
  instagram,
  facebook,
  twitter,
  linkedin,
  privacyPolicyRoute,
  termsAndConditionsRoute,
  cookiesPolicy,
  disclaimerRoute,
  contactUsRoute,
  loginRoute,
  adminApplicationsRoute
};
