import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getNewsContent, NewsItem } from 'lib/apis';

import ArticlePage from './ArticlePage';

const ArticlePageContainer = () => {
  const { id } = useParams();
  const [content, setContent] = useState<NewsItem | undefined>(undefined);

  useEffect(() => {
    getNewsContent().then(response =>
      setContent(
        response.news_items?.find(
          (item: any) => Number(item?.id) === Number(id)
        )
      )
    );
  }, [id]);

  return content ? <ArticlePage article={content} /> : null;
};

export default ArticlePageContainer;
