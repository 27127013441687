import {
  NotificationContainer
  // @ts-ignore
} from 'react-notifications';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AboutUsContent, getAboutUsContent, UpAcreTimeline } from 'lib/apis';

import { Member, AboutUsTimelineEntry } from 'components/pages/AboutUs/types';
import 'react-notifications/lib/notifications.css';
import './index.scss';
import TimelineEntry from './TimelineEntry';
import SearchBannerCTA from 'components/shared/SearchBannerCTA';

const AboutUsPage = () => {
  const [content, setContent] = useState<AboutUsContent | undefined>(undefined);

  useEffect(() => {
    getAboutUsContent().then(response => setContent(response));
  }, []);

  const timelineSort = (a: UpAcreTimeline, b: UpAcreTimeline) => {
    const dateA = a.date ? moment(a.date) : null;
    const dateB = b.date ? moment(b.date) : null;

    if (dateA === null && dateB === null) {
      return 0;
    }

    if (dateA === null) {
      return 1;
    }

    if (dateB === null) {
      return -1;
    }

    return dateA.diff(dateB);
  };

  if (content === undefined) {
    return (
      <div className="about-us-page">
        <NotificationContainer />
        <div className="about-us-page-header">
          <div className="content-container">
            <h1 className="title">About Us</h1>
          </div>
        </div>
      </div>
    );
  }

  const timelineEntries = content.up_acre_timeline.sort(timelineSort);

  return (
    <div className="about-us-page">
      <NotificationContainer />
      <div className="about-us-page-header">
        <div className="content-container">
          <h1 className="title">About Us</h1>
          <p>{content.info_a}</p>
          <div className="masonry-container-layout">
            {content.team_members?.map((member: Member) => (
              <div key={member.name} className="team-member">
                <div
                  className="team-member-profile-pic"
                  style={{ backgroundImage: `url(${member.image.url})` }}
                ></div>
                <h3 className="name">{member.name}</h3>
                <h3 className="position">{member.position}</h3>
                <p className="about">{member.about}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="our-story-section">
        <div className="content-container">
          <h2 className="title">Our Story</h2>
          <p>{content.info_b}</p>
          {timelineEntries.map(
            (timeline_entry: AboutUsTimelineEntry, idx: number) => {
              const dateString =
                timeline_entry.date === null || timeline_entry.date === ''
                  ? 'Present'
                  : moment(timeline_entry.date).format('MMMM YYYY');

              return (
                <TimelineEntry
                  key={timeline_entry.id}
                  title={timeline_entry.title}
                  date={dateString}
                  description={timeline_entry.description}
                  image={timeline_entry.image?.url}
                  flip={idx % 2 == 1}
                />
              );
            }
          )}
        </div>
      </div>
      <SearchBannerCTA title="Make The Land Work For You" />
    </div>
  );
};

export default AboutUsPage;
