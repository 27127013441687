import {
  NotificationContainer
  // @ts-ignore
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './applications.scss';
import ClipLoader from 'react-spinners/ClipLoader';
import { useEffect, useState } from 'react';
import { Application, getApplications } from 'lib/apis';
import { adminApplicationsRoute } from 'lib/constants/routes';

const AdminApplicationsPage = () => {
  const [applications, setApplications] = useState<Application[] | undefined>(
    undefined
  );

  useEffect(() => {
    getApplications().then(results => {
      setApplications(results.data.applications);
    });
  }, []);

  const renderApplications = () => {
    if (applications === undefined) {
      return (
        <tbody>
          <tr>
            <td colSpan={4} align="center">
              <ClipLoader color="#fc8124" />
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        {applications.map(application => {
          return (
            <tr key={application.id}>
              <td>
                <a href={`${adminApplicationsRoute}/${application.id}`}>
                  {application.id}
                </a>
              </td>
              <td>{application.postcode}</td>
              <td>
                {application.firstName} {application.lastName}
              </td>
              <td>{application.email}</td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="login-page">
      <NotificationContainer />
      <div className="content-container">
        <h1 className="title">Applications</h1>
      </div>
      <div className="opportunities-container">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Postcode</th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          {renderApplications()}
        </table>
      </div>
    </div>
  );
};

export default AdminApplicationsPage;
