import {
  NotificationContainer
  // @ts-ignore
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './application.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Application, getApplication } from 'lib/apis';
import ClipLoader from 'react-spinners/ClipLoader';
import { adminApplicationsRoute } from 'lib/constants/routes';

import {
  BreadcrumbItem,
  Breadcrumbs
} from 'components/shared/Breadcrumbs/Breadcrumbs';
import ApplicationMap from './ApplicationMap';

type Params = {
  applicationId: string;
};

const AdminApplicationPage: React.FC = () => {
  const { applicationId } = useParams<Params>();
  const [application, setApplication] = useState<Application | undefined>(
    undefined
  );

  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

  useEffect(() => {
    if (applicationId) {
      getApplication(applicationId).then(results => {
        setApplication(results.data.application);
        setBreadcrumbItems([
          {
            label: 'Applications',
            path: adminApplicationsRoute
          },
          {
            label: applicationId,
            path: `${adminApplicationsRoute}/${applicationId}`
          }
        ]);
      });
    }
  }, [applicationId]);

  const renderApplication = () => {
    if (application === undefined) {
      return <ClipLoader color="#fc8124" />;
    }

    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th colSpan={2}>Application Detials</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: 150, fontWeight: 'bold' }}>Application:</td>
              <td>{application.id}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>First Name:</td>
              <td>{application.firstName}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Last Name:</td>
              <td>{application.lastName}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Email:</td>
              <td>{application.email}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Phone Number:</td>
              <td>{application.phoneNumber}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Opportunity:</td>
              <td>{application.opportunity}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Price Estimate:</td>
              <td>£{application.priceEstimate.toFixed(2)}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Acres:</td>
              <td>{application.acres}</td>
            </tr>
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th>Screenshot</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img
                  src={application.screenshot}
                  style={{ maxWidth: '100%' }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th>Polygon</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {application.polygon.map((coords, index) => {
                  return (
                    <div key={`${index}_${coords.x}${coords.y}`}>
                      {coords.x}, {coords.y}
                    </div>
                  );
                })}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th>Map</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ApplicationMap
                  landPolygon={application.polygon}
                  acreage={application.acres}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <div className="application-page">
        <NotificationContainer />
        <div className="content-container">
          <h1 className="title">Application {applicationId}</h1>
        </div>
        <Breadcrumbs items={breadcrumbItems} />
        <div className="application-container">{renderApplication()}</div>
      </div>
    </div>
  );
};

export default AdminApplicationPage;
