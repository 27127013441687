import React, { useState, useEffect, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  aboutUsRoute,
  contactUsRoute,
  news,
  partnersRoute
} from 'lib/constants/routes';
import transparentHeaderRoutes from 'lib/constants/transparentHeaderRoutes';

import logo from './imgs/logo.svg';
import './index.scss';

interface Props {}

const Header: FC<Props> = () => {
  const [whiteBackground, setWhiteBackground] = useState<boolean>(false);
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [mapHeader, setMapHeader] = useState<boolean>(false);

  useEffect(() => {
    window.onscroll = changeTopWhiteState;

    return () => {
      window.onscroll = null;
    };
  }, []);

  useEffect(() => {
    changeTopWhiteState();
  }, [location.pathname]);

  const changeTopWhiteState = () => {
    if (
      window.pageYOffset === 0 &&
      [...transparentHeaderRoutes, '/'].includes(window.location.pathname)
    ) {
      setWhiteBackground(false);
    } else {
      setWhiteBackground(true);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [open]);

  useEffect(() => {
    if (location.pathname === '/map-draw') {
      setMapHeader(true);
    } else {
      setMapHeader(false);
    }
  }, [location]);

  const handleOnBurgerMenuClick = () => {
    setOpen(!open);
  };

  return (
    <header
      className={`${
        whiteBackground ? `${mapHeader ? 'mapHeader' : ''}` : 'transparent-mode'
      }`}
    >
      <div className="mediaNavbar">
        {open && (
          <div
            className={`mediaNavbar-dropDownMenu ${
              open && 'dropDownMenu-open'
            }`}
          >
            <div className="nav-item">
              <Link to={'/'} onClick={handleOnBurgerMenuClick}>
                Home
              </Link>
            </div>
            <div className="nav-item" onClick={handleOnBurgerMenuClick}>
              <Link to={aboutUsRoute}>About Us</Link>
            </div>
            <div className="nav-item" onClick={handleOnBurgerMenuClick}>
              <Link to={partnersRoute}>Partners</Link>
            </div>
            <div className="nav-item" onClick={handleOnBurgerMenuClick}>
              <Link to={news}>News</Link>
            </div>
            <div className="nav-item" onClick={handleOnBurgerMenuClick}>
              <Link to={contactUsRoute}>Contact Us</Link>
            </div>
          </div>
        )}
        <div className="mediaNavbar-logoAndBurger">
          <Link to="/" className="logo-wrapper">
            <img className="logo" src={logo} alt="upacre" />
          </Link>
          <div
            className={`navIcon ${open ? 'open' : ''}`}
            onClick={handleOnBurgerMenuClick}
          >
            {[1, 2, 3].map(line => {
              return (
                <span
                  key={line}
                  className={
                    whiteBackground || open
                      ? 'navIconDarkSpan'
                      : 'navIconLightSpan'
                  }
                ></span>
              );
            })}
          </div>
        </div>
      </div>
      <div className="navigation">
        <div className="main-nav">
          <div className="central-block">
            <div className="nav-item">
              <Link to={aboutUsRoute}>About Us</Link>
            </div>
            <div className="nav-item">
              <Link to={partnersRoute}>Partners</Link>
            </div>
            <Link to="/" className="logo-wrapper">
              <img className="logo" src={logo} alt="" />
            </Link>
            <div className="nav-item">
              <Link to={news}>News</Link>
            </div>
            <div className="nav-item">
              <Link to={contactUsRoute}>Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
