import { FC } from 'react';
import { Container, Grid } from '@mui/material';
import ClampLines from 'react-clamp-lines';
import { Link } from 'react-router-dom';
import fallbackImg from 'assets/imgs/fallback.png';
import './NewsPage.scss';
import { NewsItem } from 'lib/apis';

const NewsPage: FC<INewsPageProps> = ({ articles }) => {
  return (
    <div className="news-page">
      <div className="dashboard-top-block">News</div>
      <div className="articles-list">
        <Container className="articles-list-container">
          <Grid container spacing={3} className="articles-list-container-grid">
            {articles.map((article, index: number) => (
              <Grid key={index} item md={4}>
                <Link to={`${article?.id}`}>
                  <div className="article-card">
                    <div className="img-block">
                      <img src={article.image.url || fallbackImg} alt="" />
                    </div>
                    <div className="content">
                      <ClampLines
                        text={article.title}
                        id={`article-title ${index}`}
                        lines={1}
                        ellipsis="..."
                        className="title"
                        innerElement="div"
                        buttons={false}
                      />
                      <ClampLines
                        text={article.short_description}
                        id={`article-text ${index}`}
                        lines={3}
                        ellipsis="..."
                        className="text"
                        innerElement="div"
                        buttons={false}
                      />
                    </div>
                  </div>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export interface INewsPageProps {
  articles: NewsItem[];
}

export default NewsPage;
