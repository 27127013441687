import { FC, useState } from 'react';
import useMediaQuery from 'lib/helpers/useMediaQuery';
import { AvailableOpportunity } from 'lib/apis';

import MetricModal from './MetricModal';
import { ReactComponent as ArrowIcon } from './imgs/thin-arrow.svg';
import './DiyListItem.scss';

interface Props {
  opportunity: AvailableOpportunity;
}

const DiyListItem: FC<Props> = ({ opportunity }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  const handleShowDetails = () => {
    setShowDetails(prev => !prev);
  };

  const isTabletOrMobile = useMediaQuery('(max-width: 1280px)');

  return (
    <>
      <MetricModal
        open={!!selected}
        selected={selected}
        handleClose={() => {
          setSelected(null);
        }}
        scores={opportunity.scores}
      />
      <div
        className={`list-item-with-details ${
          showDetails ? 'details-opened' : ''
        }`}
      >
        <div className={'list-item-container'}>
          <div className="image-container">
            <div
              className="img"
              style={{
                backgroundImage: `url(${opportunity.opportunity_image})`
              }}
            >
              {isTabletOrMobile && 0.5 > 0 ? null : (
                <button type="button" onClick={() => handleShowDetails()}>
                  <ArrowIcon className={showDetails ? 'up' : ''} />
                  &nbsp; 5 Step Guide
                </button>
              )}
            </div>
          </div>
          <div className="main-content">
            <h3>{opportunity.opportunity_name}</h3>
            <p>{opportunity.opportunity_description}</p>
          </div>
          <div className="more-info-container">
            <div className={'diy-partner-info'}>
              <div
                className="img"
                style={{
                  backgroundImage: `url(${opportunity.diy_provider_image})`
                }}
              />
            </div>
            <div className="apply-button-container">
              <button
                type="button"
                className="btn-orange"
                onClick={() =>
                  window.open(opportunity.diy_provider_url, '_blank')
                }
              >
                More Info
                <ArrowIcon />
              </button>
            </div>
          </div>
          {isTabletOrMobile ? (
            <div className="more-info-btn-container">
              <button
                type="button"
                className="btn-orange"
                onClick={() => handleShowDetails()}
              >
                5 Step Guide
                <ArrowIcon className={showDetails ? 'up' : ''} />
              </button>
            </div>
          ) : null}
        </div>
        <div className={`details ${showDetails ? 'show' : ''}`}>
          <h5>A Simple Five Step Plan</h5>
          <div className="details-contents">
            <ol>
              {opportunity.steps.map(step => (
                <li key={step.id} style={{ marginBottom: 10 }}>
                  <div style={{ marginBottom: 10 }}>
                    <b>{step.step}</b>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: step.instructions }}
                  />
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiyListItem;
