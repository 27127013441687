import React from 'react';
import {
  privacyPolicyRoute,
  termsAndConditionsRoute,
  disclaimerRoute,
  cookiesPolicy,
  instagram,
  facebook,
  linkedin,
  news
} from 'lib/constants/routes';
import useAnalyticsEventTracker from 'lib/helpers/useAnalyticsEventTracker';
import { Link } from 'react-router-dom';

import Icon from './Icons';

import './index.scss';

const Footer = () => {
  const gaEventTracker = useAnalyticsEventTracker('Footer');

  return (
    <footer>
      <div className="container">
        <div className="socials">
          <a
            onClick={() => {
              gaEventTracker('facebook');
            }}
            href={facebook}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Icon.Facebook />
          </a>
          <a
            onClick={() => {
              gaEventTracker('instagram');
            }}
            href={instagram}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Icon.Instagram />
          </a>
          <a
            onClick={() => {
              gaEventTracker('linkedin');
            }}
            href={linkedin}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Icon.LinkedIn />
          </a>
        </div>
        <div className="links">
          <a href={privacyPolicyRoute} target={'_blank'} rel={'noreferrer'}>
            Privacy Notice
          </a>
          <a
            href={termsAndConditionsRoute}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Terms & Conditions
          </a>
          <a href={disclaimerRoute} target={'_blank'} rel={'noreferrer'}>
            Disclaimer
          </a>
          <a href={cookiesPolicy} target={'_blank'} rel={'noreferrer'}>
            Cookie Policy
          </a>
          <Link to={news}>News</Link>
        </div>
        <div className="addressWrapper">
          <address>
            Up Acre, Studio 2, 92 Lots Road, Chelsea, London, SW10 0QD
          </address>
        </div>
      </div>
      <div className="info">
        <div>
          <a
            onClick={() => {
              gaEventTracker('email');
            }}
            href="mailto:info@upacre.co.uk"
          >
            info@upacre.co.uk
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
